// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #f05144;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #ef5245;
  --ion-color-primary-tint: #f88a7b;
  --ion-color-primary-rgba: rgba(var(--ion-color-primary-rgb), .5);


  /** secondary **/
  --ion-color-secondary: #595959;
  --ion-color-secondary-rgb: 80, 89, 89;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  --ion-color-secondary-rgba: rgba(var(--ion-color-secondary-rgb), .5);

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  --ion-color-tertiary-rgba: rgba(var(--ion-color-tertiary-rgb), .5);

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  --ion-color-success-rgba: rgba(var(--ion-color-success-rgb), .5);
  --ion-color-success-hover: #10dc60;
  --ion-color-success-txt: #ffffff;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  --ion-color-warning-rgba: rgba(var(--ion-color-warning-rgb), .5);

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  --ion-color-danger-rgba: rgba(var(--ion-color-danger-rgb), .5);

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-rgba: rgba(var(--ion-color-dark-rgb), .5);

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-medium-rgba: rgba(var(--ion-color-medium-rgb), .5);

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-rgba: rgba(var(--ion-color-light-rgb), .5);

  --nav-background-color: #222428;
  --nav-icon-color: #ffffff;
  --nav-text-color: #ffffff;
  --nav-mobile-background-color: #222428;
  --nav-mobile-icon-color: #ffffff;
  --nav-mobile-text-color: #ffffff;

  /** background **/
  --ion-background-color: #ffffff !important;
  --ion-color-background-darker: #f3f3f3;

  /** ancillary **/
  --ion-color-light-hover: #f8f9fa;
  --border-color: #dee2e6 !important;
  --muted: #6c757d;

  // z-index
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-sticky-nav: 1021;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;

  --button-text-transform: capitalize;

//  bootstrap 5
  --bs-dark-bg-subtle: #ced4da;
  --light: #f8f9fa;

  --font-size-base: 1rem; // Assumes the browser default, typically `16px`
  --font-size-lg: calc(var(--font-size-base) * 1.25);
  --font-size-sm: calc(var(--font-size-base) * .875);
  --line-height-base: 1.5;
  --custom-control-gutter: .5rem;
  --custom-control-indicator-size: 1rem;
}

